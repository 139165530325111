import {
    Lottie,
    ReactLottieOwnProps,
    ReactLottiePlayingState
} from "@alfonmga/react-lottie-light-ts";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

export interface LottieWrapperProps {
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** Set a delay as to when the animation has to start playing (in milliseconds). NOTE: Requires isStopped to be set.*/
    delay?: number;
    /** Pauses the animation when out of view. */
    pauseOutOfView?: boolean;
    /** Repeats the animation when the mouse has re-entered and the animation is done playing. NOTE: Requires "loop" to be enabled in the config. */
    isRepeating?: boolean;
    /** Start playing the Lottie animation when the mouse is entering the SVG, note: use handlerElementRef if the trigger is needed on a different element. */
    playOnMouseEnter?: boolean;
    /** Reverse the direction of the Lottie animation when the mouse is leaving the SVG, note: use handlerElementRef if the trigger is needed on a different element. */
    reverseOnMouseLeave?: boolean;
    /** Stops the animation whenever element is out of view, plays the animation whenever the element returns to view */
    triggerWheneverInView?: boolean;
    /** Supply a ref to a component to bind the events to another element. */
    handlerElementRef?: RefObject<HTMLElement>;
    /** Bind an onClick handler to the SVG. */
    onClick?: () => void;
}

const LottieWrapper = ({
    className,
    delay,
    direction,
    handlerElementRef,
    height,
    isRepeating,
    onClick,
    config,
    pauseOutOfView = false,
    triggerWheneverInView,
    playOnMouseEnter,
    reverseOnMouseLeave,
    style,
    width,
    ...otherProps
}: ReactLottieOwnProps & LottieWrapperProps): JSX.Element => {
    const [delayIsOver, setDelayIsOver] = useState(false);
    const [hasPlayedBefore, setHasPlayedBefore] = useState(false);
    const [hasMouseEntered, setHasMouseEntered] = useState(false);
    const [hasMouseLeft, setHasMouseLeft] = useState(false);
    const [playingState, setPlayingState] =
        useState<ReactLottiePlayingState>("stopped");

    const setDelay = useRef(false);
    const { ref, inView } = useInView();

    const determinePlayingState = useCallback(() => {
        if (reverseOnMouseLeave && !hasPlayedBefore) {
            setPlayingState("stopped");
        } else if (reverseOnMouseLeave && hasPlayedBefore) {
            setPlayingState("playing");
        }

        if (playOnMouseEnter && !hasMouseEntered) {
            setPlayingState("stopped");
        } else if (playOnMouseEnter && hasMouseEntered) {
            setPlayingState("playing");
        }
    }, [
        hasMouseEntered,
        hasPlayedBefore,
        playOnMouseEnter,
        reverseOnMouseLeave
    ]);

    const determineDirection = () => {
        if (reverseOnMouseLeave && hasMouseLeft) {
            return -1;
        }

        return direction;
    };

    /*
     * Afaik, the library we use for Lottie animations does not make it possible
     * to access the animation progress directly, so this is a workaround to set
     * the animation playhead back to the start and play it again.
     * */
    useEffect(() => {
        if (playingState === "stopped" && playOnMouseEnter && isRepeating) {
            setPlayingState("playing");
        }
    }, [isRepeating, playOnMouseEnter, playingState]);

    const mouseEnterHandler = () => {
        if (delayIsOver) {
            determinePlayingState();
            setHasPlayedBefore(true);
            setHasMouseLeft(false);
            setHasMouseEntered(true);

            if (playOnMouseEnter && isRepeating) {
                setPlayingState("stopped");
            }
        }
    };

    const mouseLeaveHandler = () => {
        if (delayIsOver) {
            determinePlayingState();
            setHasPlayedBefore(true);
            setHasMouseEntered(false);
            setHasMouseLeft(true);
        }
    };

    // This useEffect handles the initial state.
    useEffect(() => {
        determinePlayingState();
    }, [hasMouseEntered, hasMouseLeft, determinePlayingState]);

    // This useEffect handles the logic of delaying animations.
    useEffect(() => {
        if (typeof delay === "number" && !setDelay.current) {
            const delayTimeout = setTimeout(() => {
                setPlayingState("playing");
                setDelayIsOver(true);
            }, delay);
            setDelay.current = true;

            return () => {
                setDelay.current = false;
                clearTimeout(delayTimeout);
            };
        } else if (typeof delay === "undefined") {
            setDelayIsOver(true);
        }
    }, [delay, delayIsOver]);

    // This useEffect handles the logic of pausing animations when out of view.
    useEffect(() => {
        if (pauseOutOfView) {
            if (inView) {
                setPlayingState("playing");
            } else {
                setPlayingState("paused");
            }
        }
    }, [pauseOutOfView, inView]);

    // This useEffect handles the logic on which eventListeners to add when "handlerElementRef" && "playOnMouseEnter" are set.
    useEffect(() => {
        if (handlerElementRef && playOnMouseEnter) {
            const copiedHandlerElementRef = handlerElementRef?.current;
            copiedHandlerElementRef?.addEventListener(
                "mouseenter",
                mouseEnterHandler
            );

            return () => {
                copiedHandlerElementRef?.removeEventListener(
                    "mouseenter",
                    mouseEnterHandler
                );
            };
        }
    });

    // This useEffect handles the logic on retriggering Animation when items enters viewport
    useEffect(() => {
        if (triggerWheneverInView) {
            setPlayingState("playing");
        }
    }, [triggerWheneverInView]);

    // This useEffect handles the logic on which eventListeners to add when "handlerElementRef" & "reverseOnMouseLeave" are set.
    useEffect(() => {
        if (handlerElementRef && reverseOnMouseLeave) {
            const copiedHandlerElementRef = handlerElementRef?.current;
            copiedHandlerElementRef?.addEventListener(
                "mouseenter",
                mouseEnterHandler
            );
            copiedHandlerElementRef?.addEventListener(
                "mouseleave",
                mouseLeaveHandler
            );

            return () => {
                copiedHandlerElementRef?.removeEventListener(
                    "mouseenter",
                    mouseEnterHandler
                );
                copiedHandlerElementRef?.removeEventListener(
                    "mouseleave",
                    mouseLeaveHandler
                );
            };
        }
    });

    const LottieComponent = (
        <Lottie
            config={config}
            direction={determineDirection()}
            playingState={playingState}
            lottieEventListeners={[
                {
                    callback: isRepeating
                        ? () => setPlayingState("stopped")
                        : () => null,
                    name: "loopComplete"
                }
            ]}
            {...otherProps}
        />
    );

    return (
        <div
            ref={ref}
            className={className}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style={{ ...style, height, width }}
            onMouseEnter={
                !handlerElementRef && (playOnMouseEnter || reverseOnMouseLeave)
                    ? () => mouseEnterHandler()
                    : () => null
            }
            onMouseLeave={
                !handlerElementRef && reverseOnMouseLeave
                    ? () => mouseLeaveHandler()
                    : () => null
            }
        >
            {onClick ? (
                <StyledButton aria-label="animation" onClick={onClick}>
                    {LottieComponent}
                </StyledButton>
            ) : (
                LottieComponent
            )}
        </div>
    );
};

const StyledButton = styled.button`
    background: none;
    border: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
    outline: 0;
    padding: 0;
`;

export default LottieWrapper;
